/* UtcClock.css */

.utc-clock-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
 
 
  border-radius: 5px;
  margin-bottom: 50px;
  transition: background-color 0.5s ease;
  height: 1vh;
  color: white;
}

.utc-clock-banner.highlight {

  background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(39, 55, 75) 45%, #3e3043 75%); /* Highlight color for active periods */
}

.utc-time {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.countdown {
  text-align: center;
}

/* Add more styles as needed */

